import React, { CSSProperties, FunctionComponent } from 'react';
import {
    Button,
    Col, Container, Image, Modal, Row, Spinner,
} from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TItemInfo } from '../../utils/driveapi';
import DriveBrowserItem from './driveBrowserItem'

export type TDriveBrowserGridProps = {
    touchScreen: boolean,
    path: string,
    items: TItemInfo[],
    selectedItem: TItemInfo | null;

    onItemSelect: (item: TItemInfo)=>void;
    onItemDelete: (item: TItemInfo)=>void;
    onItemInfo: (item: TItemInfo)=>void;
    onItemOpen: (item: TItemInfo)=>void;
}

export function DriveBrowserGrid(props: TDriveBrowserGridProps) {
    const itemProps = {
        showDelete: true,
        showInfo: true,
        disableDelete: !props.path.startsWith("/uploads"),
        disableInfo: false
    }

    const itemClick = (item: TItemInfo) => {
        if(!props.touchScreen) {
            props.onItemSelect(item);
        } else {
            props.onItemOpen(item);
        }
    }

    const itemCell = (item: TItemInfo) => (
        <DriveBrowserItem {...item} 
            key={item.id} 
            selected={props.selectedItem === item} 
            onClick={() => itemClick(item)} 
            onDoubleClick={() => props.onItemOpen(item)}
            onDeleteClicked={() => props.onItemDelete(item)}
            onInfoClicked={() => props.onItemInfo(item)}
            {...itemProps}
            showMenu={props.touchScreen && item.type === "file"}
        />        
    )

    return <div className="drive-browser-grid">
        <div className="dbg-grid dbg-folders">
            {
                props.items.filter((item) => item.type != "file" && item.type != "link").map((item) => itemCell(item))
            }
        </div>
        <div className="dbg-grid dbg-files">
            {
                props.items.filter((item) => item.type == "file" || item.type == "link").map((item) => itemCell(item))
            }
        </div>
    </div>
}

export type TDriveBrowserScrollableGridProps = {
    hasMore: boolean;
    next: ()=>Promise<void>;
} & TDriveBrowserGridProps

export function DriveBrowserScrollableGrid(props: TDriveBrowserScrollableGridProps) {
    return <div className="drive-browser-scrollable-grid" id="dbg-scrollable-div">
        <InfiniteScroll
            dataLength={props.items.length} // This is important field to render the next data
            next={props.next}
            hasMore={props.hasMore}
            scrollableTarget="dbg-scrollable-div"
            loader={(
                <Col className="col-12 p-3" style={{ textAlign: 'center' }}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </Col>
            )}
            endMessage={(
                <Col className="col-12 p-3" style={{ textAlign: 'center' }}>
                </Col>
            )}
        >
            <DriveBrowserGrid {...props}/>

        </InfiniteScroll>
    </div>
}
