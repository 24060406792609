import React, { useContext } from 'react';
import delay from 'delay';
import { withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { ToastContainer, toast } from 'react-toastify';
import cookies from 'browser-cookies';
import rollbar from '../../utils/setUpRollbar';
import { getBlobUrl, getApiUrl } from '../../config/api';
import 'react-toastify/dist/ReactToastify.css';
import LoggingInLoader from './loggingInLoader';
import { matchPath, RouteComponentProps, withRouter } from 'react-router-dom';
import LaunchOculusDialogBox from '../../components/launchOculusDialog';
import ContactUsDialog, { ContactUsRequest } from '../../components/contactUsDialog';
import BackgroundTaskRunner from '../../components/backgroundTaskRunner';
import api, { FetchError, FetchOptions } from '../../utils/api';
import eventBus from '../../utils/eventBus';
import UpdateBillingInfoDialog from '../../components/updateBillingInfoDialog';
import MergeAccountsDialog, { MergeAccountsRequest } from '../../components/mergeAccountsDialog';

/*
 WITH MULTIVERSE PROVIDER

 This component is used a wrapper on the whole project.
 It is required for the WithMultiverseApi Higher-Order-Component (HOC) to work.

 See https://reactjs.org/docs/higher-order-components.html for more details

 The WithMultiverseApi HOC is used to allow any component in the project to access the global data and functions related to Multiverse. See src/components/isAuthenticated.ts for an example of a component using the HOC.

Components using the HOC will have the state of the WithMultiverseProvider injected as props, which is defined below
 */
type MultiverseApiContextState = {
    multiverse: { // All Components using the HOC will have a multiverse prop
        isAuthenticated: boolean, // Indicates if the user is logged in through auth0 and multiverse
        isLoading: boolean, // Indicates if the user in the process of logging through auth0 or multiverse
        user?: MultiverseUser, // The user entity object
        memberOf?: MultiverseDomain[]
    } & ProvidedMethods
    domain?: MultiverseDomain,
    childProduct?: MultiverseProduct,
    launchRequest?: MultiverseLaunchRequest,
    contactUsRequest?: ContactUsRequest,
    mergeAccountsRequest?: MergeAccountsRequest
};


/* 
The following methods are also provided
 */
type ProvidedMethods = {
    // Generic HTTP requests to the api
    // Handles some of the errors which the server can respond with
    // Will reauthenticate if a `user-merged` error is returned
    put<T>(opts: FetchOptions | string, body?: any): Promise<T>,
    post<T>(opts: FetchOptions | string, body?: any): Promise<T>,
    get<T>(opts: FetchOptions | string, body?: any): Promise<T>,
    del<T>(opts: FetchOptions | string, body?: any): Promise<T>,

    reauthenticate(): Promise<void>, // Repeats authenetication logic for when a user's account has been merged.
    updateUser(): Promise<void>, // Requests the latest version of the user from the API, and updates the user field
    overrideUser(user: MultiverseUser): void, // Overrides the user object with a new object
    logout(): Promise<void>, // Calls auth0 logout with clean up
    getUserIconUrl(): string | undefined, // Returns the url for the user icon
    openDomainPath(domain_id: string, path: string): Promise<any>; //Opens a sub-path that corresponds to a domain, caching domain info in the process
    showErrorToast(error: Error | string): void; //show error message
    getCurrentDomainId(): string | undefined; //returns domain id parsed from current uri
    getCurrentChildProductId(): string | undefined; //returns child product id parsed from current uri
    refreshCurrentDomain(force?:boolean): void; //tells HOC domain info may be updated so it should refresh
    refreshCurrentChildProduct(force?:boolean): void; //refresh child product
    getMissionConfigsForChildProduct(): Promise<IGetMissionConfigsResult>; // Requests the mission configs for the current child product
    getLocationsForChildProduct(): Promise<MultiverseLocation[]>; // Requests the locations for the current child product

    requestOculusLaunch(req: MultiverseLaunchRequest): void;
    cancelOculusLaunch(): void;

    requestContactUs(req: ContactUsRequest): void;
    closeContactUs(): void;

    requestMergeAccounts(req: MergeAccountsRequest): void;
    closeMergeAccounts(): void;
    

};



// The magic substring that causes the browser to reload
const ACCOUNT_HAS_BEEN_MERGED_ERROR = 'Account has been merged';

const AUTH0_ACCESS_TOKEN_COOKIE_EXPIRE_TIME = 60 * 60; // One Hour
const MULTIVERSE_USER_COOKIE_EXPIRE_TIME = 60 * 60; // One Hour

type CanContainId = {
    id?: string
}

type CanContainUnderscoreId = {
    _id?: string
}

export type CanContainChildProduct = {
    child_product?: string
}

type CanContainTimestamp = {
    timestamp?: string
}

export type MultiverseBaseEntity = {
    id: string;
    name: string;
    nickname?: string;
    type: string;
    path: string;
    iconblob?: string;
    doorimageblob?: string;
    bannerblob?: string;
    owner: string;
    visibility?: string;
    domain?: string;
    discoveryiconblob?: string;
    discoverybannerblob?: string;
    description?: string;
    readonly?: boolean;
    readonly_message?: string;
}

export type MultiverseUser = {
    tags: string[],
    auth0userid?: string,
    oculusaccount?: string,
    steamaccount?: string,
    iconblob: string,
    nickname: string,
    created: Date,
    timestamp: Date,
    permissions: string[],
    email?: string,
    acceptedtandcs?: boolean
    receiveupdates?: boolean;
} & MultiverseBaseEntity;

export type MultiverseAuthData = {
    product: string;
    token: string;
}

export type MultiverseLocation = {
    release_android_dev?: string;
    release_android_prod?: string;
    release_win64_dev?: string;
    release_win64_prod?: string;
    approval?: string;
    approvalmessage?: string;    
} & MultiverseBaseEntity;

export type MultiverseRoom = {
    template?: string;
    releaseof?: string;
    releasename?: string;
} & MultiverseBaseEntity;

export type MultiverseEvent = {
    location?: string;
    locationuri?: string;
    startdate?: string;
    enddate?: string;
    agegroup?: string;
    published?: boolean;
    oculuseventid?: string,
    oculusapproved?: boolean,
    subscribers?: number,
    oculussubmission?: string,
    oculusbannerblob?: string,
    oculusnotes?: string
} & MultiverseBaseEntity;

export const isLockedEvent = (ev?: any) => {
    if(!ev || ev.type != 'event') {
        return false
    }
    return ev.oculussubmission === 'accepted' || (ev.oculuseventid && ev.oculuseventid.length > 0)
}

export type MultiverseFile = {
    mimetype?: string;
    width?: number;
    height?: number;
    blob?: string;
    link?: string;
    size?: number;
} & MultiverseBaseEntity;

export type MultiverseDomain = {
    uri: string;
    plan?: string;
    activated?: boolean;
    description?: string;
    domainPermissions?: string[];
    oculusdestination?: string;
    stripesubscription?: string;
    trialend?: Date;
    domainaccess: string;
    ispersonal: boolean;
    buildingaccess?: boolean;
} & MultiverseBaseEntity

export type MultiverseProduct = {
    id: string;
    parent_product?: string;
    name?: string;
    oculus_appid?: string;
    oculus_token?: string;
    quest_appid?: string;
    quest_token?: string;
    go_appid?: string;
    go_token?: string;
    steam_appid?: string;
    steam_user_key?: string;
    steam_publisher_key?: string;
    viveport_appid?: string;
    root_domain?: string;
};

export type MultiverseDomainWithPermissions = {
    domainPermissions: string[];
} & MultiverseDomain

export type MultiversePlanTemplate = {
    name: string;
    template: string;
}

export type MultiversePlan = {
    type: string;
    name: string;
    minMembers: number;
    maxMembers: number;
    payment: "free" | "oculus" | "stripe" | "contact";
    stripeId?: string;
    oculusId?: string;
    canStream?: boolean;
    canInviteByLink?: boolean;
    canInviteByEmail?: boolean;
    canUseGoogleDrive?: boolean;
    canUseVimeo?: boolean;
    canManageRoles?: boolean;
    canUseVideoLink?: boolean;
    canUseYoutube?: boolean;
    templates: MultiversePlanTemplate[]
}

export type InfiniverseAdvert = {
    _id: string;
    name: string;
    timestamp: Date;
    remainingimpressions: number;
    totalimpressions: number;
    imageurl: string;
    iconurl: string;
    approved: boolean;
    pendingimpressions: number;
    rejectionreason?: string;
    paymentfailure?: string;
    raw?: Record<string, any>; //Admin only, showing raw data
}

export type InfiniverseSlot = {
    buildingguid: string,
    slot: number,
    marketsale: number,
    refuseoffers: boolean
}
export type InfiniverseEscrowHolding = {

}
export type InfiniverseEscrowHoldingInfo = {
    escrowid : string,
    timestamp: Date,
    amount : number,
    fees : number,
    senderid: string,
    receiverid: string,
    itemid: string,
    itemindex: number,
    sendername: string,
    receivername: string,
    itemname: string,
    status: string,
    arhiviedon: Date,
}
export type InfiniverseUserEscrowInfos = {
    sent: InfiniverseEscrowHoldingInfo[],
    received: InfiniverseEscrowHoldingInfo[]
}

// MissionConfig

export type MultiverseMissionConfigCreateArgs = {
    location: string,
    description: string,
    metadata: any,
    selection_weight: number,
    banner_blob: string,
    feature?: string,
    importance?: number,
    environment?: string,
    mode?: string
}

export type MultiverseMissionConfigUpdateArgs =
    MultiverseMissionConfigCreateArgs & CanContainId;

export type MultiverseMissionConfig =
    MultiverseMissionConfigUpdateArgs & 
    CanContainTimestamp &
    CanContainChildProduct;

// MissionSettings

export type MultiverseTime = {
    hour?: number,
    minute?: number
}

export type MultiverseMissionEventCycleCreateArgs = {
    mission_event_config_name: string,
    /**
     * 0 = Sunday, 1 = Monday, etc.
     */
    day_of_week_utc?: number,    
    start_time_utc?: MultiverseTime,
    end_time_utc?: MultiverseTime,
    /**
     * 0 = left helicopter, 1 = right helicopter
     */
    option?: number,
    disabled?: boolean 
}

export type MultiverseMissionEventCycle = MultiverseMissionEventCycleCreateArgs & CanContainUnderscoreId;

export type MultiverseUpdateIconsArgs = {
}

export type MultiverseMissionIcon = {
    /**
     * Identifier, must match the file name
     */
    name: string,
    blob?: string,
    timestamp?: Date
}

export type MultiverseEnvironment = {
    /**
     * The name of the asset
     */
    name: string,
    /**
     * Displayed in the UI
     */
    display_name?: string,
    selection_weight: number
}

export type MultiverseMissionMode = {
    /**
     * Unique name
     */
    name: string,    
    /**
     * Displayed in the UI
     */
    display_name?: string,
    /**
     * CSV of JSON name, value pairs
     */
    metadata: string,
    selection_weight: number,
    /**
     * Icon names must match the names in the icons array and the file names
     */
    icons?: string[]
}

export type MultiverseMissionSettingsCreateArgs = {
    special_modes: MultiverseMissionMode[],
    special_mode_environments: MultiverseEnvironment[],
    normal_mode_environments: MultiverseEnvironment[],
    icons: MultiverseMissionIcon[],
    event_cycles: MultiverseMissionEventCycle[]
}

export type MultiverseMissionSettingsUpdateArgs =
    MultiverseMissionSettingsCreateArgs & CanContainId;

export type MultiverseMissionSettings = MultiverseMissionSettingsUpdateArgs & 
    CanContainTimestamp &
    CanContainChildProduct;

// Mission Event Config

export type MultiverseMissionEventConfigCreateArgs = {
    name: string,
    display_name?: string,
    importance?: number,
    icon?: string,
    modes?: string[],
    environment?: string,
    any_mode?: boolean,
    no_mode?: boolean
}

export type MultiverseMissionEventConfigUpdateArgs = 
    MultiverseMissionEventConfigCreateArgs & CanContainId;

export type MultiverseMissionEventConfig = MultiverseMissionEventConfigUpdateArgs & 
    CanContainTimestamp &
    CanContainChildProduct;

// Mission Event

export type MultiverseMissionEventCreateArgs = {
    mission_event_config: string,
    startdate?: Date,
    enddate?: Date,
    option?: number
}

export type MultiverseMissionEventUpdateArgs = 
    MultiverseMissionEventCreateArgs & CanContainId;

export type MultiverseMissionEvent =MultiverseMissionEventUpdateArgs & 
    CanContainTimestamp &
    CanContainChildProduct;

// Missions

export type MultiverseMissionCreateArgs = {
    startdate: Date,
    option: number,                
    mission_config: string,
    feature?: string,
    event?: string,
    importance?: number
}

export type MultiverseMissionUpdateArgs =
    MultiverseMissionCreateArgs & CanContainId;

export type MultiverseMission = MultiverseMissionUpdateArgs & 
    CanContainTimestamp &
    CanContainChildProduct;

export type MultiverseAvatarSkin = {
    name: string,
    unlocklevel: number,
    iap_id?: string,
    index: number    
}

export type MultiverseAvatarCreateArgs = {
    name: string,
    unlocklevel: number,
    skins: MultiverseAvatarSkin[],
    weaponskins: MultiverseAvatarSkin[],
    default?: boolean,
    iap_id?: string,
    disabled: boolean
}

export type MultiverseAvatarUpdateArgs = MultiverseAvatarCreateArgs & {
    product: string,
    timestamp?: Date
}

export type MultiverseAvatar = MultiverseAvatarCreateArgs & {
    _id: string
}

//get human-friendly display options for the plan info and expiry 
export function getDomainPlanInfo(domain: {plan?:string, activated?:boolean, trialend?:Date, ispersonal?: boolean}) {
    //if no valid plan just return free
    if(!domain.plan) {
        return { nameText: "Free", expiresText: "", expiresColor: "black" }
    } 
    
    //reformat plan name to be nice
    let text = domain.plan
        .replaceAll("-trial", " Trial")               //replace "-trial" with " Trial"
        .replaceAll("-stripe", "")                      //remove "-stripe"
        .replaceAll("-custom", "")                      //remove "-stripe"
        .replace(/\b(\w)/, (x) => x.toUpperCase());     //make camel case

    if(domain.ispersonal) {
        text += " (personal)";
    }

    //check domain status for text
    if(!domain.activated) {
        //not activated so return big red 'Expired' text
        return {
            nameText: text,
            expiresText: "Expired",
            expiresColor: "red"
        }
    } else if(domain.plan.endsWith("-trial")) {
        //trial period so display days remaining, in red if nearly expired
        const end = domain.trialend ? new Date(domain.trialend) : new Date();
        const remaining_days = Math.floor((end.getTime() - Date.now()) / (24*60*60*1000));
        if(remaining_days >= 0) {
            //normal case is to display expiration days
            return {
                nameText: text,
                expiresText: `${remaining_days} days left`,
                expiresColor: remaining_days < 5 ? "red" : "black"
            }
        } else {
            //this case should never normally happen as server should deactivate expired trials,
            //but handle it just in case by showing expiry text
            return {
                nameText: text,
                expiresText: "Expiring Now",
                expiresColor: "red"
            }
        }
    } else {
        //all good so just show name and no expiry data
        return {
            nameText: text,
            expiresText: "",
            expiresColor: "black"
        }
    }
}

export type MultiverseDomainMember = {
    domain: string;
    id: string;

    permissions: string[];
    product: string;
    timestamp: Date;
    user: string;
}
export type MultiverseDomainMemberListResult = {
    member: {
        permissions: string[]
    }
    userentity: MultiverseUser
}

export type MultiverseUseInviteResult = {
    domain: MultiverseDomain;
    member: MultiverseDomainMember;
}

export type MultiverseLaunchRequest = {
    title: string;
    domain?: string;
    location?: string;
    inviteSecret?: string;
    prompt?: undefined | 'none' | 'spinner' | 'confirm'
}
export type MultiverseLaunchResult = {
    launched: boolean;
}

export interface IGetMissionConfigsResult {
    mission_configs: MultiverseMissionConfig[]
}

const INITIAL_CONTEXT: MultiverseApiContextState = {
    multiverse: {
        isLoading: true,
        isAuthenticated: false,
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        put: () => {
            throw new Error('Multiverse provider not ready');
        },
        post: () => {
            throw new Error('Multiverse provider not ready');
        },
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        get: () => {
            throw new Error('Multiverse provider not ready');
        },
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        del: () => {
            throw new Error('Multiverse provider not ready');
        },
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        reauthenticate: () => {
            throw new Error('Multiverse provider not ready');
        },
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        logout: () => {
            throw new Error('Multiverse provider not ready');
        },
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        updateUser: () => {
            throw new Error('Multiverse provider not ready');
        },
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        overrideUser: () => {
            throw new Error('Multiverse provider not ready');
        },
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        getUserIconUrl: () => {
            throw new Error('Multiverse provider not ready');
        },
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        openDomainPath: () => {
            throw new Error('Multiverse provider not ready');
        },
        showErrorToast: () => {
            throw new Error('Multiverse provider not ready');
        },
        getCurrentDomainId: () => {
            throw new Error('Multiverse provider not ready');
        },
        getCurrentChildProductId: () => {
            throw new Error('Multiverse provider not ready');
        },
        refreshCurrentChildProduct: () => {
            throw new Error('Multiverse provider not ready');
        },
        refreshCurrentDomain: () => {
            throw new Error('Multiverse provider not ready');
        },
        getMissionConfigsForChildProduct: () => {
            throw new Error('Multiverse provider not ready');
        },
        getLocationsForChildProduct: () => {
            throw new Error('Multiverse provider not ready');
        },
        requestOculusLaunch: () => {
            throw new Error('Multiverse provider not ready');
        },
        cancelOculusLaunch: () => {
            throw new Error('Multiverse provider not ready');
        },
        requestContactUs: () => {
            throw new Error('Multiverse provider not ready');
        },
        closeContactUs: () => {
            throw new Error('Multiverse provider not ready');
        },
        requestMergeAccounts: () => {
            throw new Error('Multiverse provider not ready');
        },
        closeMergeAccounts: () => {
            throw new Error('Multiverse provider not ready');
        },
    }
};

export type WithMultiverseApiProps = MultiverseApiContextState;
export const MultiverseApiContext: React.Context<WithMultiverseApiProps> = React.createContext(INITIAL_CONTEXT);

export const useMultiverseContext = () => useContext(MultiverseApiContext)

export const usePermission = (permission: string) => {
    const context = useMultiverseContext();
    return context.multiverse.user && context.multiverse.user.permissions && context.multiverse.user.permissions.includes(permission) || false
}

type MultiverseApiContextProps = WithAuth0Props & RouteComponentProps;

type IdMatchParams = {
    id: string;
}

class MultiverseApiContextProvider extends React.Component<MultiverseApiContextProps, MultiverseApiContextState> {
    constructor(props: MultiverseApiContextProps) {
        super(props);
        //console.log("MultiverseApiContextProvider.constructor");
        this.state = {
            multiverse: {
                isAuthenticated: false,
                isLoading: true,
                user: undefined,
                put: this.put,
                post: this.post,
                get: this.get,
                del: this.del,
                updateUser: this.updateUser,
                overrideUser: this.overrideUser,
                logout: this.logout,
                reauthenticate: this.reauthenticate,
                getUserIconUrl: this.getUserIconUrl,
                openDomainPath: this.openDomainPath,
                showErrorToast: this.showErrorToast,
                getCurrentDomainId: this.getCurrentDomainId,
                getCurrentChildProductId: this.getCurrentChildProductId,
                refreshCurrentDomain: this.refreshCurrentDomain,
                refreshCurrentChildProduct: this.refreshCurrentChildProduct,
                getMissionConfigsForChildProduct: this.getMissionConfigsForChildProduct,
                getLocationsForChildProduct: this.getLocationsForChildProduct,
                requestOculusLaunch: this.requestOculusLaunch,
                cancelOculusLaunch: this.cancelOculusLaunch,
                requestContactUs: this.requestContactUs,
                closeContactUs: this.closeContactUs,
                requestMergeAccounts: this.requestMergeAccounts,
                closeMergeAccounts: this.closeMergeAccounts
            },
            domain: undefined,
            childProduct: undefined,
            launchRequest: undefined,
            contactUsRequest: undefined
        };
    }

    //mount just loads current domain from uri if present
    componentDidMount = () => {
        //console.log("MultiverseApiContextProvider.componentDidMount");
        this.refreshCurrentDomain();
        eventBus.on("API.fetcherror",this.onFetchError);
    }
    componentWillUnmount = () => {
        eventBus.remove("API.fetcherror",this.onFetchError);
    }

    //on update, reauthenticates if necessary and ensures domain is up to date
    componentDidUpdate = (prevProps: MultiverseApiContextProps, prevState: MultiverseApiContextState): Promise<void> => {
        const { auth0 } = this.props;
        const { multiverse } = this.state;

        //console.log("MultiverseApiContextProvider.componentDidUpdate");
        //console.log(auth0);
        //console.log(multiverse);

        //check if switched from not auth0 authenticated to authenticated
        if (auth0.isAuthenticated && !prevProps.auth0.isAuthenticated) {
            //console.log("Auth 0 switching to authenticated - calling multiverse authenticate");
            return this.multiverseAuthenticate();
        }

        //TODO: This logic seems wrong! 
        if (!auth0.isLoading && !auth0.isAuthenticated && prevProps.auth0.isLoading) {
            this.setState({
                multiverse: {
                    ...multiverse,
                    isLoading: false,
                },
            });
        }

        //reload current domain
        this.refreshCurrentDomain();
        this.refreshCurrentChildProduct();

        return Promise.resolve();
    };

    //check document uri for a potential domain id
    getCurrentDomainId = (): string | undefined =>  {
        const match = matchPath<IdMatchParams>(this.props.history.location.pathname, {
            path: '/domains/:id',
        })

        const id = match?.params.id;
        return id;        
    }

    //if current uri references a product, this loads the product info and stores it for
    //access by other systems. if not, sets product to undefined.
    refreshCurrentChildProduct = async (force?: boolean): Promise<void> => {
        const id = this.getCurrentChildProductId();
        const curr_id = this.state.childProduct?.id;
        if( (id !== curr_id) || force) {   
            if(id) {
                const product = await this.get<MultiverseProduct>(`/v2/admin/childproducts/${id}`);
                console.log({id, child_product: product});
                this.setState({ 
                    childProduct: product
                })  
            } else {
                this.setState({
                    childProduct: undefined
                })  
            }      
        }
    }

    getMissionConfigsForChildProduct = async () => {
        //load mission configs
        const result = await this.get<IGetMissionConfigsResult>(
            `/v2/admin/childproducts/${this.getCurrentChildProductId()}/missions/configs`);

        return result;
    }    

    getLocationsForChildProduct = async () => {
        await this.refreshCurrentChildProduct();

        if(!this.state.childProduct) return [];

        //load locations
        const result = await this.get<MultiverseLocation[]>(
            `/v2/domains/${this.state.childProduct.root_domain}/locations`);

        return result;
    }    

    //check api uri for a potential child product id
    getCurrentChildProductId = (): string | undefined =>  {
        const match = matchPath<IdMatchParams>(this.props.history.location.pathname, {
            path: [
                '/admin/childproducts/:id',
                '/admin/childproducts/:id/:submenu'
            ]
        })
        const id = match?.params.id;
        return id;        
    }

    //if current uri references a domain, this loads the domain info and stores it for
    //access by other systems. if not, sets domain to undefined.
    refreshCurrentDomain = async (force?: boolean): Promise<void> => {
        const id = this.getCurrentDomainId();
        const curr_id = this.state.domain?.id;
        const curr_uri= this.state.domain?.uri;
        if( (id !== curr_id && id !== curr_uri) || force) {   
            if(id) {
                const domain = await this.get<MultiverseDomain>(this.state.multiverse.isAuthenticated ? `/v2/domains/${id}` : `/v2/public/domains/${id}`);
                console.log({id, domain});
                this.setState({ 
                    domain
                })  
            } else {
                this.setState({
                    domain: undefined
                })  
            }      
        }
    }

    requestOculusLaunch = (opts: MultiverseLaunchRequest) => {
        this.setState({
            launchRequest: opts
        })
    }
    cancelOculusLaunch = () => {
        this.setState({
            launchRequest: undefined
        })
    }

    requestContactUs = (req: ContactUsRequest): void => {
        this.setState({
            contactUsRequest: req
        })
    }
    closeContactUs = (): void => {
        this.setState({
            contactUsRequest: undefined
        })
    }

    requestMergeAccounts = (req: MergeAccountsRequest): void => {
        this.setState({
            mergeAccountsRequest: req
        })
    }
    closeMergeAccounts = (): void => {
        this.setState({
            mergeAccountsRequest: undefined
        })
    }

    //handle errors by showing 'toast' message
    componentDidCatch(error: Error): void {
        const { multiverse } = this.state;
        toast(error.message, { type: 'error' });
        this.setState({
            multiverse: {
                ...multiverse,
            },
        });
    }

    //does silent request for an AUTH0 access token (using during re-login)
    //token is cached in auth0AccessToken cookie for future requests
    getAccessToken = async (): Promise<string> => {
        const existingToken = cookies.get('auth0AccessToken');
        if (existingToken) {
            return existingToken;
        }
        const { auth0: { getAccessTokenSilently } } = this.props;
        const token = await getAccessTokenSilently();
        document.cookie = `auth0AccessToken=${token}; max-age=${AUTH0_ACCESS_TOKEN_COOKIE_EXPIRE_TIME}; path=/;`;
        return token;
    };

    //requests multiverse access token via the auth0login endpoint. 
    getUserAndMultiverseToken = async (auth0token: string, useCache?: boolean): Promise<MultiverseUser & MultiverseAuthData> => {
        //if we already have a full cached user entity + shapevr token, just return them
        console.log("getUserAndMultiverseToken")
        try {
            const cachedUser = cookies.get('cachedUserObject');
            const multiverseToken = cookies.get('shapevr-token');
            if (useCache && cachedUser && multiverseToken) {
                console.log("getUserAndMultiverseToken returning cached data");
                return JSON.parse(cachedUser) as (MultiverseUser & MultiverseAuthData);
            }
        } catch(err) {
            console.log("Error using cached data");
            console.log(err);
        }

        //attempt the login
        const user = await this.post<MultiverseUser & MultiverseAuthData>('/entity/user/auth0login', {
            auth0token,
            buildnumber: 999999,
            build: '5a31556041915942fc8e2563',
            productname: 'multiverse',
        }); 
        console.log("getUserAndMultiverseToken returning loaded user");

        //store lots of results as cookies for future requests
        api.onAuthenticated(user);
        return user;
    };

    //get blob url for current user's icon
    getUserIconUrl = (): string | undefined => {
        const { multiverse } = this.state;
        const { auth0 } = this.props as { auth0: { user: { picture?: string } } };
        const multiverseIconBlob = multiverse?.user?.iconblob;
        if (multiverseIconBlob) return `${getApiUrl()}/v2/profilepicture?forcereload=${multiverseIconBlob}`;
        // return `${getBlobUrl()}/${multiverseIconBlob}`;
        return auth0?.user?.picture;
    };

    //full multiverse authenticated, first ensures we have an auth0 token, then does login
    //via multiverse api
    multiverseAuthenticate = async (): Promise<void> => {
        const { auth0: { logout } } = this.props;
        try {
            console.log(`multiverseAuthenticate requesting new auth0 access token`);     
            const auth0token = await this.getAccessToken();

            this.setState({
                multiverse: {
                    ...this.state.multiverse,
                    isAuthenticated: false,
                    isLoading: true,
                },
                launchRequest: undefined
            });

            console.log(`authenticating on multiverse serverr with auth0 token ${auth0token}`);
            const user = await this.getUserAndMultiverseToken(auth0token, false);
            rollbar.configure({
                payload: {
                    person: {
                        id: user.id,
                        username: user.name,
                    },
                },
            });
  
            console.log("authentication complete, new user object:")
            console.log(user);
            this.setState({
                multiverse: {
                    ...this.state.multiverse,
                    isAuthenticated: true,
                    user,
                },
            });

            console.log("refreshing current domain after authentication")
            await this.refreshCurrentDomain(true);

            console.log("refreshing domain membership after authentication")
            await this.refreshDomainMembership()

            this.setState({
                multiverse: {
                    ...this.state.multiverse,
                    isLoading: false,
                },
            });

        } catch (e) {
            console.log(e);
            this.setState({
                multiverse: {
                    ...this.state.multiverse,
                    isAuthenticated: false,
                    isLoading: false,
                },
            });
            logout({
                localOnly: true,
            });
        }
    };

    //used during account merge to force re-authentication to occur, on the basis that the current
    //account may have been entirely deleted from the server
    reauthenticate = async (): Promise<void> => {
        api.onUnauthenticated();
        await this.multiverseAuthenticate();
    };

    //clears all login data and calls auth0 logout function, passing in origin
    //of root domain uri to return to home page
    // eslint-disable-next-line @typescript-eslint/require-await
    logout = async (): Promise<void> => {
        const { auth0: { logout } } = this.props;
        api.onUnauthenticated();
        logout({
            returnTo: window.location.origin,
        });
    };

    //refreshes user data
    updateUser = async (): Promise<void> => {
        const { multiverse: { user } } = this.state;
        if (!user) throw Error('Not yet set user id');
        try {
            const newUser = await this.get<MultiverseUser>(`/v2/users/${user.id}`);
            this.overrideUser(newUser);
        } catch (e) {
            throw e;
        }
    };

    //refreshes domains list
    refreshDomainMembership = async(): Promise<void> => {
        const { multiverse } = this.state;
        try {
            const membership = await this.get<MultiverseDomain[]>('/v2/domains');
            this.setState({
                multiverse: {
                    ...multiverse,
                    memberOf: membership
                }
            })
        } catch (e) {
            throw e;
        }        
    }

    //helper to generate a uri that references a page associated with a domain
    //and then push it to the history
    //i.e. /domains/<id>/<path>
    openDomainPath = async (domain_id: string, path: string): Promise<any> => {
        this.props.history.push(`/domains/${domain_id}${path}`)
        return {};
    }

    //Kept for backwards compatibility, just call through to the global API
    put = <T extends unknown>(opts: FetchOptions | string, body?: any): Promise<T> => {
        return api.put(opts, body);
    } 
    post = <T extends unknown>(opts: FetchOptions | string, body?: any): Promise<T> => {
        return api.post(opts, body);
    } 
    get = <T extends unknown>(opts: FetchOptions | string): Promise<T> => {
        return api.get(opts);
    } 
    del = <T extends unknown>(opts: FetchOptions | string, body?: any): Promise<T> => {
        return api.del(opts, body);
    } 

    //event triggered by api when unhandled fetch error occurs
    onFetchError = (err: FetchError) => {
        this.showErrorToast(err);
    }

    //forcibly overwrites cached user state. predominantly for re-auth or profile updates,
    //when new user info is retreived but we don't want to have to re-request it from servers
    overrideUser = (newUser: MultiverseUser) => {
        const { multiverse: { user, ...rest } } = this.state;
        document.cookie = `cachedUserObject=${JSON.stringify(newUser)}; max-age=${MULTIVERSE_USER_COOKIE_EXPIRE_TIME}; path=/;`;
        this.setState({ multiverse: { user: newUser, ...rest } });
    };

    //shows the toast error message
    // eslint-disable-next-line class-methods-use-this
    showErrorToast = (error: Error | string) => {
        const errorString = typeof error === 'string' ? error : error.message;
        toast(errorString, {
            type: 'error',
        });
    }

    //render shows oculus dialog+toast container, along with any other children
    render = (): JSX.Element => {
        const { children, auth0 } = this.props;
        const { multiverse, domain, childProduct, launchRequest, contactUsRequest, mergeAccountsRequest } = this.state;

        //show spinner whilst authenticating
        if (multiverse.isLoading || auth0.isLoading) {
            return <LoggingInLoader />;
        }

        //show spinner if an active, silent launch is in progress
        let show_content = true;
        if(launchRequest) {
            const prompt = launchRequest.prompt || 'none';
            if(prompt === 'none') {
                show_content = false;
            }
        }

        //normal stuff
        return (
            <MultiverseApiContext.Provider value={{ multiverse, domain, childProduct, launchRequest, contactUsRequest }}>
                <LaunchOculusDialogBox/>
                <ContactUsDialog/>
                <UpdateBillingInfoDialog authenticated={multiverse.isAuthenticated}/>
                <MergeAccountsDialog currentRequest={mergeAccountsRequest} onClose={this.closeMergeAccounts}/>
                {show_content && children}
                {!show_content && <LoggingInLoader />}
                <BackgroundTaskRunner/>
                <ToastContainer />
            </MultiverseApiContext.Provider>
        );
    };
}

export default withRouter(withAuth0(MultiverseApiContextProvider));
