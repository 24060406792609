import { MultiverseFile, WithMultiverseApiProps } from "../hoc/multiverseApiProvider";
import * as tus from 'tus-js-client'
import { getBlobUrl } from "../config/api";
import BGTaskManager, { TBackgroundTaskArgs } from "./backgroundTasks";
import api from "./api";

export type TUploadContextOpts = {
    uri: string;
    file: File;
    alwaysBackground?: boolean;
    onProgress?: (progress: number, status: string)=>void;
};

//main video upload body that fires off a tus upload and resolves promise when full upload is complete
const vimeoUpload = (opts: TUploadContextOpts, upload_link: string, task_args: TBackgroundTaskArgs): Promise<void> => {
    const promise = new Promise<void>((resolve, reject) => {
        var upload = new tus.Upload(opts.file, {
            uploadUrl: upload_link,
            chunkSize: 16*1024*1024,
            onError: (error) => {
                reject(error);
            },
            onSuccess: () => {
                resolve();
            },
            onProgress: (bytesSent: number, bytesTotal: number) => {
                //notify both task and caller of progress
                const progress = bytesSent / bytesTotal;
                if(task_args.onProgress) {
                    task_args.onProgress(progress, "uploading")
                }
                if(opts.onProgress) {
                    opts.onProgress(progress, "uploading")
                }
            }
        })
        upload.start()
    })
    return promise;
}

//check status of the video upload
const vimeoCheckStatus = async(opts: TUploadContextOpts, vimeo_uri: string) => {
    return (await api.post<any>({
        url: `/v2/${opts.uri}/content/videoupload/status`,
        body: {
            uri: vimeo_uri
        }
    })).status;     
}

//calls check status with initial delay of 10s
const vimeoWaitAndCheckStatus = (opts: TUploadContextOpts, vimeo_uri: string): Promise<any> => {
    const promise = new Promise<void>((resolve, reject) => {
        const to = setTimeout(() => {
            clearTimeout(to);
            vimeoCheckStatus(opts, vimeo_uri)
            .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err);
            })
        }, 10000)
    })
    return promise;
}

//main video upload body, wraps the firing off of a background task that does the full video
//upload process and then resolves the promise with the resulting stream url
function videoUpload(opts: TUploadContextOpts): Promise<MultiverseFile> {
    const { uri, file } = opts;
    if(opts.onProgress) {
        opts.onProgress(0, "pending");
    }
    return new Promise<MultiverseFile>((resolve,reject) => {
        BGTaskManager.addBackgroundTask({
            name: file.name,
            task: async(args: TBackgroundTaskArgs) => {
                try {

                    //start with video upload begin, which may immediately return streamurl for existing video,
                    //or may return TUS upload data for the upload
                    const begin_ul_res = await api.post<any>({
                        url: `/v2/${uri}/content/videoupload/begin`,
                        body: {
                            name: file.name,
                            size: file.size,
                            lastModified: file.lastModified
                        }
                    })

                    //check for streamurl, and if not found, begin upload
                    let entity: MultiverseFile | null = begin_ul_res.entity;
                    if(!entity && begin_ul_res.upload) {

                        //notify task+caller of progress
                        if(args.onProgress) {
                            args.onProgress(0, "uploading")
                        }
                        if(opts.onProgress) {
                            opts.onProgress(0, "uploading");
                        }

                        //do main video upload
                        await vimeoUpload(opts, begin_ul_res.upload.upload_link, args);

                        //initial check of status to get initial state
                        {
                            const status = await vimeoCheckStatus(opts, begin_ul_res.uri);
                            if(args.onProgress) {
                                args.onProgress(100, status)
                            }
                            if(opts.onProgress) {
                                opts.onProgress(100, status);
                            }
                        }

                        //loop with waiting checks of status to wait for transcoding to complete
                        while(true) {
                            const status = await vimeoWaitAndCheckStatus(opts, begin_ul_res.uri);
                            if(args.onProgress) {
                                args.onProgress(100, status)
                            }
                            if(opts.onProgress) {
                                opts.onProgress(100, status);
                            }
                            if(status === "available") {
                                break;
                            }
                        }

                        //finish the upload, which should return the streamurl
                        const end_ul_res = await api.post<any>({
                            url: `/v2/${uri}/content/videoupload/end`,
                            body: {
                                uri: begin_ul_res.uri
                            }
                        })
                        entity = end_ul_res; //the result is a file entity with the 'link' property set
                    }
                    if(!entity) {
                        throw new Error("Video upload failed");
                    }

                    resolve(entity);

                } catch (err) {
                    reject(err);
                }
            } 
        })
    })
}

async function fileUploadTask(opts: TUploadContextOpts): Promise<MultiverseFile> {
    const { uri, file } = opts;
    const res = await api.post<MultiverseFile>({
        url: `/v2/${uri}/content?name=${file.name}`,
        body: file,
        hideErrors: true,
        headers: {
            'Content-Type': file.type,
            'x-shapevr-name': file.name,
        }
    });
    if(!res.blob) {
        throw new Error("File upload failed");
    }
    return res;
}

function fileUpload(opts: TUploadContextOpts) : Promise<MultiverseFile> {
    const { file } = opts;
    if(!opts.alwaysBackground && opts.file.type.startsWith("image/")) {
        return fileUploadTask(opts);
    } else {
        return new Promise<MultiverseFile>((resolve,reject) => {
            BGTaskManager.addBackgroundTask({
                name: file.name,
                task: async(args: TBackgroundTaskArgs) => {
                    try {
                        if(args.onProgress) {
                            args.onProgress(100,"transcoding");
                        }
                        if(opts.onProgress) {
                            opts.onProgress(100,"transcoding");
                        }
                        resolve(await fileUploadTask(opts));
                    } catch(err) {
                        reject(err);
                    }
                }
            })   
        })
    }
}

export async function uploadContent(opts: TUploadContextOpts): Promise<MultiverseFile> {
    const { file } = opts;
    if(file.type.startsWith("video/")) {
        return await videoUpload(opts);
    } else {
        return await fileUpload(opts);
    }
}